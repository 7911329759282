<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-card-title class="headline">
          {{ $t("nav.help") }} - {{ $t("nav.care").toLowerCase() }}
        </v-card-title>
      </v-col>
    </v-row>
    <v-expansion-panels>
      <template v-for="(url, title) in videos">
        <help-video-frame
          :key="title"
          :videoTitle="title"
          :videoSource="url"
        ></help-video-frame>
      </template>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import HelpVideoFrame from "@/views/helpvideos/HelpVideoFrame.vue";
export default {
  components: {
    HelpVideoFrame,
  },
  data() {
    return {
      videos: {
        "video-tutorials.care.video-1":
          "https://www.youtube.com/embed/TeQOFIiX000",
        "video-tutorials.care.video-2":
          "https://www.youtube.com/embed/LUzjRh3qm2Y",
        "video-tutorials.care.video-3":
          "https://www.youtube.com/embed/2xZVZOp8yUo",
        "video-tutorials.care.video-4":
          "https://www.youtube.com/embed/xfjsBQUkuzg",
        "video-tutorials.care.video-5":
          "https://www.youtube.com/embed/1rx3vgPDGBA",
        "video-tutorials.care.video-6":
          "https://www.youtube.com/embed/yYaYDPMv7Lo",
        "video-tutorials.care.video-7":
          "https://www.youtube.com/embed/k9rD8hc3jak",
        "video-tutorials.care.video-8":
          " https://www.youtube.com/embed/bQeCD30a6FA",
        "video-tutorials.care.video-9":
          "https://www.youtube.com/embed/aOteK3QQsRc",
        "video-tutorials.care.video-10":
          " https://www.youtube.com/embed/-HPY_QpzoIo",
        "video-tutorials.care.video-11":
          "https://www.youtube.com/embed/yvzWhZsU3hE",
        "video-tutorials.care.video-12":
          "https://www.youtube.com/embed/85NxThKQXHQ",
        "video-tutorials.care.video-13":
          "https://www.youtube.com/embed/gUQVYMBr2KI",
        "video-tutorials.care.video-14":
          "https://www.youtube.com/embed/ZVVe1FVxubc",
        "video-tutorials.care.video-15":
          "https://www.youtube.com/embed/FFuUEJamW2w",
      },
    };
  },
};
</script>
